module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@bottlebooks/gatsby-theme-event/gatsby-browser.js'),
      options: {"plugins":[],"rootPath":"/de","homePath":"https://www.vievinum.at/","exhibitorBase":"aussteller","producerBase":"winzer","productBase":"weine","eventId":"5d99aa29a7274933e0337a76","locale":"de","showCountryFilter":true,"showRegionFilter":true,"showCityFilter":true,"landingPageFilter":{"unserweinPremium":{"eq":"yes"},"mainImage":{"url":{"ne":null}},"logo":{"url":{"ne":null}}}},
    },{
      plugin: require('../node_modules/@bottlebooks/gatsby-theme-event/gatsby-browser.js'),
      options: {"plugins":[],"rootPath":"/en","homePath":"https://www.vievinum.com/","exhibitorBase":"exhibitors","producerBase":"producers","productBase":"wines","eventId":"5d99aa29a7274933e0337a76","locale":"en","showCountryFilter":true,"showRegionFilter":true,"showCityFilter":true,"landingPageFilter":{"unserweinPremium":{"eq":"yes"},"mainImage":{"url":{"ne":null}},"logo":{"url":{"ne":null}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-44225398-5","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
